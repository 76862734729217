import { connect } from "react-redux";
import isUtc from "showio/components/Date/isUtc";

const Timezone = ({ timezone }) => {
  if (isUtc({ timezone })) {
    return "UTC";
  } else {
    return "";
  }
};

const mapStateToProps = state => {
  return {
    timezone: state.preferences.timeZone
  };
};

export default connect(mapStateToProps)(Timezone);
