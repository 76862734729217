import React from "react";
import { connect } from "react-redux";

/**
 * Higher order component to inject security context to another component.

 * @param Component
 * @returns
 */
const withSecurityContext = (Component) => {
  const ComposedComponent = (props) => {
    return <Component {...props} />;
  };

  function mapStateToProps(state) {
    return {
      securityContext: state.securityContext
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};

export default withSecurityContext;
