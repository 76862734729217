import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "15px"
        }
      }
    }
  },
  lightPrimaryTextColor: "#fff",
  lightSecondaryTextColor: "rgba(255, 255, 255, 0.75)",
  darkPrimaryTextColor: "rgba(0, 0, 0, 0.87)",
  darkSecondaryTextColor: "rgba(0, 0, 0, 0.6)",
  movement: {
    pastMovementColor: "#d1d1d1",
    currentMovementColor: "#6eb059",
    futureMovementColor: "#4d85bd",
    inactiveMovementColor: "#b62c26",
    datelessMovementColor: "#eee"
  },
  gantt: {
    headerActiveBgColor: "#4b7e36",
    headerInactiveBgColor: "#686868"
  }
});

export default theme;
