import { connect } from "react-redux";
import formatDate from "showio/components/Date/formatDate";

const Date = ({ date, format, timezone }) => {
  return formatDate({ date, format, timezone });
};

const mapStateToProps = state => {
  return {
    timezone: state.preferences.timeZone
  };
};

export default connect(mapStateToProps)(Date);
