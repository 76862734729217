// @flow
import Timezone from "showio/components/Date/Timezone";
import type { Preferences } from "showio/reducers/Preferences";
import { ACCESS_CODE_LOGIN_METHOD } from "showio/reducers/Preferences";
import { SET_LOGIN_METHOD, SET_PREFERENCES, SET_TIME_ZONE } from "showio/reducers/PreferencesActions";

const INITIAL_STATE: Preferences = {
  loginMethod: ACCESS_CODE_LOGIN_METHOD,
  timeZone: Timezone.UTC
};

const reduce = (state: Preferences = INITIAL_STATE, action: any): Preferences => {
  switch (action.type) {
    case SET_PREFERENCES:
      return { ...state, ...action.preferences };
    case SET_LOGIN_METHOD:
      return { ...state, loginMethod: action.loginMethod };
    case SET_TIME_ZONE:
      return { ...state, timeZone: action.timeZone };
    default:
      return state;
  }
};

export default reduce;
