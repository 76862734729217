// @flow
import jwtDecode from "jwt-decode";
import UserRoles from "showio/services/user/UserRole";

export type UserDetails = {
  token: string,
  username: string,
  scope: number,
  roles: Array<string>
};

export type SecurityContext = {
  authenticated: boolean,
  userDetails: ?UserDetails,
};

export type TokenData = {
  sub: string,
  scope: string,
  roles: string,
  defaultTimeZone: string
}

/**
 * Returns true if user is authenticated, otherwise false.
 *
 * @param securityContext
 * @returns
 */
export const isAuthenticated = (securityContext: SecurityContext): boolean => {
  if (securityContext == null) {
    throw new Error("Security context cannot be null");
  }
  if (securityContext.authenticated == null) {
    throw new Error("Authenticated cannot be null");
  }

  return securityContext.authenticated === true;
};

export const getRoles = (securityContext: SecurityContext): Array<string> => {
  if (securityContext == null) {
    throw new Error("Security context cannot be null");
  }

  if (securityContext.userDetails == null
    || securityContext.userDetails.roles == null) {
    return [];
  }

  return securityContext.userDetails.roles;
};

/**
 * Returns true if any role in security context matches expected role, otherwise false.
 *
 * @param expectedRole
 * @param securityContext
 * @returns
 */
export const hasRole = (expectedRole: string, securityContext: SecurityContext): boolean => {
  if (expectedRole == null) {
    throw new Error("Expected role cannot be null")
  }

  return getRoles(securityContext).includes(expectedRole.trim());
};

export const getScope = (securityContext: SecurityContext): ?number => {
  if (securityContext == null) {
    throw new Error("Security context cannot be null");
  }
  if (securityContext.userDetails == null) {
    return null;
  }

  return securityContext.userDetails.scope;
};

/**
 * Returns true if scope in security context matches expected scope, otherwise false.
 *
 * @param expectedScope
 * @param securityContext
 * @returns
 */
export const hasScope = (expectedScope: number, securityContext: SecurityContext): boolean => {
  if (expectedScope == null) {
    throw new Error("Expected scope cannot be null")
  }
  if (securityContext == null) {
    throw new Error("Security context cannot be null");
  }
  if (securityContext.userDetails == null) {
    return false;
  }

  return securityContext.userDetails.scope === expectedScope;
};

export const hasRoleOnlyCurrentSchedule = (securityContext: SecurityContext): boolean => {
  return isAuthenticated(securityContext) && hasRole(UserRoles.ROLE_ONLY_CURRENT_SCHEDULE, securityContext);
};

export const parseToken = (token: string): TokenData => {
  if (token == null || token.trim().length === 0) {
    throw new Error("Invalid token");
  }

  return jwtDecode(token);
};

export const buildUserDetails = (token: string, tokenData: TokenData): UserDetails => {
  if (token == null) {
    throw new Error("Invalid token");
  }
  if (tokenData == null) {
    throw new Error("Invalid token");
  }
  if (tokenData.sub == null) {
    throw new Error("Invalid token");
  }

  const username = tokenData.sub;
  const scope = Number(tokenData.scope);
  const roles = tokenData.roles == null ? [] : tokenData.roles.split(",");

  return {
    token: token,
    username: username,
    scope: scope,
    roles: roles
  }
};

export const buildSecurityContext = (token: string, tokenData: TokenData): SecurityContext => {
  const userDetails = tokenData != null ? buildUserDetails(token, tokenData) : null;
  return {
    authenticated: token != null,
    userDetails: userDetails
  }
};
