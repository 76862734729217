import moment from "moment";
import { useEffect, useState } from "react";

const useCurrentTime = () => {
  const [savedCurrentTime, setCurrentTime] = useState(moment().startOf("minute"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment().startOf("minute");

      if (currentTime.diff(savedCurrentTime, "minutes") !== 0) {
        setCurrentTime(currentTime);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  });

  return savedCurrentTime;
}

export default useCurrentTime;