// @flow
import type { SecurityContext } from "showio/reducers/SecurityContext";
import type { SecurityContextActions } from "showio/reducers/SecurityContextActions";
import { RESET_SECURITY_CONTEXT, SET_SECURITY_CONTEXT } from "showio/reducers/SecurityContextActions";

const INITIAL_STATE: SecurityContext = {
  authenticated: false,
  userDetails: null
};

const reduce = (state: SecurityContext = INITIAL_STATE, action: SecurityContextActions): SecurityContext => {
  switch (action.type) {
    case SET_SECURITY_CONTEXT:
      return action.securityContext;
    case RESET_SECURITY_CONTEXT:
      return {
        authenticated: false,
        userDetails: null
      };
    default:
      return state;
  }
};

export default reduce;
