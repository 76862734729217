import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import PasswordField from "showio/components/PasswordField";

const PREFIX = 'AccessCodeLogin';
const classes = {
  form: `${PREFIX}-form`,
  submitButton: `${PREFIX}-submit-button`,
  submitRoot: `${PREFIX}-submit-root`,
  textField: `${PREFIX}-text-field`,
  progressRoot: `${PREFIX}-progress-root`,
  progress: `${PREFIX}-progress`,
  paperError: `${PREFIX}-paper-error`
};

const styles = ({ theme }) => ({
  [`&.${classes.form}`]: {
    width: "100%",
    marginTop: theme.spacing()
  },
  [`& .${classes.submitRoot}`]: {
    width: "40%",
    margin: "0 auto"
  },
  [`& .${classes.submitButton}`]: {
    marginTop: theme.spacing(3),
    backgroundColor: "#0086C2",
    color: "#fff"
  },
  [`& .${classes.textField}`]: {
    width: "100%"
  },
  [`& .${classes.progressRoot}`]: {
    marginTop: "20px",
    width: "40%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  [`& .${classes.progress}`]: {
    width: "30px"
  },
  [`& .${classes.paperError}`]: {
    width: "60%",
    margin: "0 auto",
    backgroundColor: "#f44336",
    color: "white",
    textAlign: "center"
  }
});

const AccessCodeLogin = ({ className, loadingLogin, errorMessage, onSignIn }) => {

  const [accessCode, setAccessCode] = useState("");

  const onChangeAccessCode = event =>
    setAccessCode(event.target.value);

  const onSubmit = event => {
    event.preventDefault();
    onSignIn({
      accessCode: accessCode
    });
  };

  const isError = Boolean(errorMessage);

  return (
    <form className={className + " " + classes.form}>
      <PasswordField
        error={isError}
        id="access-code"
        label="Access Code"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={accessCode}
        onChange={onChangeAccessCode}
      />

      <Paper className={classes.paperError}>{errorMessage}</Paper>

      <div className={classes.submitRoot}>
        {
          loadingLogin ? (
            <div className={classes.progressRoot}>
              <CircularProgress className={classes.progress}/>
            </div>
          ) : (
            <Button
              fullWidth
              variant="contained"
              className={classes.submitButton}
              onClick={onSubmit}
              type="submit"
            >
              ENTER
            </Button>
          )
        }
      </div>
    </form>
  );
};

export default styled(AccessCodeLogin)(styles);