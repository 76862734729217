import { LOADING_LOGOUT } from "showio/actions/type";

const INITIAL_STATE = {
  loadingLogout: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_LOGOUT:
      return { ...state, loadingLogout: action.payload };
    default:
      return state;
  }
};

export default authReducer;
