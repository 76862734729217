import React from "react";
import { Redirect, Route } from "react-router-dom";
import withSecurityContext from "showio/components/withSecurityContext";
import { isAuthenticated } from "showio/reducers/SecurityContext";

const SecuredRoute = ({
  securityContext,
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated(securityContext)) {
          return <Redirect to="/"/>;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default withSecurityContext(SecuredRoute);
