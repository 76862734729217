// @flow
import type { SecurityContext } from "showio/reducers/SecurityContext";

export const SET_SECURITY_CONTEXT = "SET_SECURITY_CONTEXT";
export const RESET_SECURITY_CONTEXT = "RESET_SECURITY_CONTEXT";

type SetSecurityContext = {
  type: "SET_SECURITY_CONTEXT",
  securityContext: SecurityContext
};

type ResetSecurityContext = {
  type: "RESET_SECURITY_CONTEXT"
};

export type SecurityContextActions = SetSecurityContext | ResetSecurityContext;

export const setSecurityContext = (securityContext: SecurityContext): SetSecurityContext => {
  return {
    type: SET_SECURITY_CONTEXT,
    securityContext: securityContext
  };
};

export const resetSecurityContext = (): ResetSecurityContext => {
  return {
    type: RESET_SECURITY_CONTEXT
  };
};
