import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import React, { useState } from 'react';
import PasswordField from "showio/components/PasswordField";

const PREFIX = 'UsernamePasswordLogin';
const classes = {
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  submitBtn: `${PREFIX}-submitBtn`,
  textField: `${PREFIX}-textField`,
  progress: `${PREFIX}-progress`,
  progressWidth: `${PREFIX}-progressWidth`,
  paperError: `${PREFIX}-paperError`
};

const styles = ({ theme }) => ({
  [`&.${classes.form}`]: {
    width: "100%",
    marginTop: theme.spacing()
  },
  [`& .${classes.submit}`]: {
    marginTop: theme.spacing(3),
    backgroundColor: "#0086C2",
    color: "#fff"
  },
  [`& .${classes.submitBtn}`]: {
    width: "40%",
    margin: "0 auto"
  },
  [`& .${classes.textField}`]: {
    width: "100%"
  },
  [`& .${classes.progress}`]: {
    marginTop: "20px",
    width: "40%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  [`& .${classes.progressWidth}`]: {
    width: "30px"
  },
  [`& .${classes.paperError}`]: {
    width: "60%",
    margin: "0 auto",
    backgroundColor: "#f44336",
    color: "white",
    textAlign: "center"
  }
});

const UsernamePasswordLogin = ({ className, loadingLogin, errorMessage, onSignIn }) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onChangeUsername = event =>
    setUsername(event.target.value);

  const onChangePassword = event =>
    setPassword(event.target.value);

  const onSubmit = event => {
    event.preventDefault();
    onSignIn({
      username: username,
      password: password
    });
  };

  const isError = Boolean(errorMessage);

  return (
    <form className={className + " " + classes.form}>
      <TextField
        error={isError}
        id="login-name"
        label="Login Name"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={username}
        onChange={onChangeUsername}
      />
      <PasswordField
        error={isError}
        id="password"
        label="Password"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={password}
        onChange={onChangePassword}
      />

      <Paper className={classes.paperError}>{errorMessage}</Paper>

      <div className={classes.submitBtn}>
        {
          loadingLogin ? (
            <div className={classes.progress}>
              <CircularProgress className={classes.progressWidth}/>
            </div>
          ) : (
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={onSubmit}
              type="submit"
            >
              ENTER
            </Button>
          )
        }
      </div>
    </form>
  );
};

export default styled(UsernamePasswordLogin)(styles);