import { SWITCH_VIEW } from "showio/actions/type";

const INITIAL_STATE = {
  view: "timeline"
};

const scheduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SWITCH_VIEW:
      return { ...state, view: action.payload };
    default:
      return state;
  }
};

export default scheduleReducer;
