import Link from "@mui/material/Link";
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import AccessCodeLogin from "showio/components/Login/AccessCodeLogin";
import LinkedAccessCodeLogin from "showio/components/Login/LinkedAccessCodeLogin";
import UsernamePasswordLogin from "showio/components/Login/UsernamePasswordLogin";
import showioLogo from "showio/img/show-logo.svg";
import { ACCESS_CODE_LOGIN_METHOD, USERNAME_PASSWORD_LOGIN_METHOD } from "showio/reducers/Preferences";
import { setLoginMethod, setTimeZone } from "showio/reducers/PreferencesActions"
import {
  buildSecurityContext,
  hasRoleOnlyCurrentSchedule,
  isAuthenticated,
  parseToken
} from "showio/reducers/SecurityContext";
import { setSecurityContext } from "showio/reducers/SecurityContextActions"
import authorizationRepository from "showio/repositories/authorizationRepository";

const PREFIX = 'Login';
const classes = {
  main: `${PREFIX}-main`,
  paper: `${PREFIX}-paper`,
  loginMethodSwitch: `${PREFIX}-login-method-switch`,
  logo: `${PREFIX}-logo`
};

const styles = ({ theme }) => ({
  [`&.${classes.main}`]: {
    width: "auto",
    [theme.breakpoints.up(490)]: {
      width: 490,
      marginLeft: "auto",
      marginRight: "auto"
    },
    transform: "translateY(-30px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
  },
  [`& .${classes.loginMethodSwitch}`]: {
    marginTop: theme.spacing(2),
    cursor: "pointer"
  },
  [`& .${classes.logo}`]: {
    maxWidth: "100%",
    height: "auto"
  }
});

const Login = ({ className, history, preferences, setLoginMethod, setTimeZone, securityContext, setSecurityContext }) => {
  const location = useLocation();
  const [loginState, setLoginState] = useState({
    errorMessage: null,
    loadingLogin: false
  });
  const { errorMessage, loadingLogin } = loginState;
  const locationLoginMethod = getLocationLoginMethod(location);
  const loginMethod = coalesce(locationLoginMethod, preferences.loginMethod, ACCESS_CODE_LOGIN_METHOD);
  const [accessCode] = getQueryParams(["code"], location);
  const requiresUserInput = accessCode == null;

  const doSignIn = async (credentials) => {
    if (loginState.loadingLogin) {
      // sign in is already in progress
      return;
    }

    setLoginState({
      ...loginState,
      errorMessage: null,
      loadingLogin: true
    });

    try {
      const { data: { token } } = await authorizationRepository.signIn(credentials);
      const tokenData = token != null ? parseToken(token) : null;
      const securityContext = buildSecurityContext(token, tokenData);

      localStorage.setItem("token", token);

      setSecurityContext(securityContext);
      setTimeZone(tokenData.defaultTimeZone);
      doSetLoginMethod(loginMethod);
      setLoginState({
        ...loginState,
        errorMessage: null,
        loadingLogin: false
      });

      if (hasRoleOnlyCurrentSchedule(securityContext)) {
        history.replace("/day/current/schedules");
      } else {
        history.replace("/schedules");
      }
    } catch (e) {
      const message = loginMethod === USERNAME_PASSWORD_LOGIN_METHOD
        ? "Invalid username or password"
        : "Invalid access code";
      setLoginState({
        ...loginState,
        errorMessage: message,
        loadingLogin: false
      })
    }
  };

  const doSetLoginMethod = (loginMethod) => {
    setLoginMethod(loginMethod);

    if (loginMethod === USERNAME_PASSWORD_LOGIN_METHOD) {
      history.replace("/password");
    } else {
      history.replace("/access");
    }
  };

  if (loginMethod === ACCESS_CODE_LOGIN_METHOD
    && accessCode != null && accessCode !== ""
    && !loadingLogin
    && (!isAuthenticated(securityContext))
    && (errorMessage == null || errorMessage === "")) {
    doSignIn({
      accessCode: accessCode
    });
  }

  return (
    <main className={className + " " + classes.main}>
      <div className={classes.paper}>
        <img src={showioLogo} className={classes.logo} alt="showio"/>

        {
          renderLoginComponent({
            className: className,
            requiresUserInput: requiresUserInput,
            loginMethod: loginMethod,
            setLoginMethod: doSetLoginMethod,
            classes: classes,
            loadingLogin: loadingLogin,
            errorMessage: errorMessage,
            history: history,
            onSignIn: doSignIn
          })
        }
      </div>
    </main>
  );
};

const renderLoginComponent = ({ className, loginMethod, setLoginMethod, requiresUserInput, ...props }) => {
  if (requiresUserInput) {
    switch (loginMethod) {
      case ACCESS_CODE_LOGIN_METHOD:
        return (
          <>
            <AccessCodeLogin {...props}/>
            <Link
              className={classes.loginMethodSwitch}
              onClick={() => setLoginMethod(USERNAME_PASSWORD_LOGIN_METHOD)}
            >
              Or enter your login and password
            </Link>
          </>
        );
      case USERNAME_PASSWORD_LOGIN_METHOD:
        return (
          <>
            <UsernamePasswordLogin {...props}/>

            <Link
              className={classes.loginMethodSwitch}
              onClick={() => setLoginMethod(ACCESS_CODE_LOGIN_METHOD)}
            >
              Or enter access code
            </Link>
          </>
        );
      default:
        throw new Error("Unknown login method");
    }
  }

  return (
    <>
      <LinkedAccessCodeLogin
        {...props}
        errorMessage={props.errorMessage ? "Could not sign in using provided link" : ""}/>
      {props.errorMessage ?
        <>
          <Link
            className={classes.loginMethodSwitch}
            onClick={() => setLoginMethod(USERNAME_PASSWORD_LOGIN_METHOD)}
          >
            Try login and password
          </Link>

          <Link
            className={classes.loginMethodSwitch}
            onClick={() => setLoginMethod(ACCESS_CODE_LOGIN_METHOD)}
          >
            Or enter access code
          </Link>
        </>
        : null
      }
    </>
  );
};

const getQueryParams = (names, location) => {
  const urlSearchParams = new URLSearchParams(location.search);
  return names.map(name => urlSearchParams.get(name));
};

const getLocationLoginMethod = (location) => {
  if (location == null) {
    return null;
  }

  const pathname = location.pathname;

  if (pathname == null) {
    return null;
  }

  let trimmedPathname = pathname.trim();

  if (trimmedPathname.startsWith("/")) {
    trimmedPathname = trimmedPathname.substring(1, trimmedPathname.length);
  }
  if (trimmedPathname.endsWith("/")) {
    trimmedPathname = trimmedPathname.substring(0, trimmedPathname.length - 1);
  }

  if (trimmedPathname === "") {
    return null;
  } else if (trimmedPathname === "access") {
    return ACCESS_CODE_LOGIN_METHOD;
  } else if (trimmedPathname === "password") {
    return USERNAME_PASSWORD_LOGIN_METHOD;
  } else {
    console.warn("Cannot resolve login method. Falling back to default.");
    return null;
  }
};

const coalesce = (a, b, c) => {
  if (a != null) {
    return a;
  }
  if (b != null) {
    return b;
  }
  return c;
};

function mapStateToProps(state) {
  return {
    preferences: state.preferences,
    securityContext: state.securityContext
  };
}

export default connect(
  mapStateToProps,
  { setLoginMethod, setTimeZone, setSecurityContext }
)(styled(Login)(styles));
