import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { hasRoleOnlyCurrentSchedule } from "showio/reducers/SecurityContext";

/**
 * Higher order component check if security context contains role to see only current schedule.
 * If it has it will redirect.
 *
 * @param Component
 * @returns
 */
const onlyCurrentScheduleRedirect = (Component) => {
  const ComposedComponent = ({ _onlyCurrentScheduleProp, ...props }) => {
    return _onlyCurrentScheduleProp
      ? (<Redirect to="/current"/>)
      : (<Component {...props} />);
  };

  function mapStateToProps(state) {
    return {
      _onlyCurrentScheduleProp: hasRoleOnlyCurrentSchedule(state.securityContext)
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};

export default onlyCurrentScheduleRedirect;
