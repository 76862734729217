import { LOADING_LOGOUT, SWITCH_VIEW, } from "showio/actions/type";
import { resetSecurityContext } from "showio/reducers/SecurityContextActions";
import authorizationRepository from "showio/repositories/authorizationRepository";

export const switchView = view => ({
  type: SWITCH_VIEW,
  payload: view
});

export const logout = callback => async dispatch => {
  dispatch({ type: LOADING_LOGOUT, payload: true });
  try {
    const tokenFromLS = localStorage.getItem("token");
    await authorizationRepository.signOut({ token: tokenFromLS });

    const loginMethod = localStorage.getItem("loginMethod");
    localStorage.clear();
    // preserve login method
    if (loginMethod != null) {
      localStorage.setItem("loginMethod", loginMethod);
    }

    dispatch({ type: LOADING_LOGOUT, payload: false });
    dispatch(resetSecurityContext());
    dispatch(switchView("timeline"));
    callback();
  } catch (error) {
    // do nothing
  }
};
