import { combineReducers } from "redux";
import auth from "showio/reducers/auth";
import preferencesReducer from "showio/reducers/PreferencesReducer";
import schedule from "showio/reducers/schedule";
import securityContextReducer from "showio/reducers/SecurityContextReducer";

export default combineReducers({
  securityContext: securityContextReducer,
  preferences: preferencesReducer,
  auth,
  schedule
});
