import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import App from "showio/App";
import reducers from "showio/reducers";
import { buildSecurityContext, parseToken } from "showio/reducers/SecurityContext";
import * as serviceWorker from "showio/serviceWorker";
import theme from "showio/theme";
import "./index.css";

const token = localStorage.getItem("token");
const loginMethod = localStorage.getItem("loginMethod");
const timeZone = localStorage.getItem("timezone");
const tokenData = token != null ? parseToken(token) : null;
const isMobile = window.matchMedia("(max-width: 20cm)").matches;

const store = createStore(
  reducers,
  {
    securityContext: buildSecurityContext(token, tokenData),
    preferences: {
      // repair any saved "null" string
      loginMethod: (loginMethod == null || loginMethod === "null") ? null : loginMethod,
      timeZone: timeZone == null ? null : Number(JSON.parse(timeZone))
    },
    schedule: {
      view: isMobile ? "table" : "timeline"
    }
  },
  applyMiddleware(thunk)
);

const Root = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);

const rootElement = document.getElementById("root");
render(<Root/>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
