import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'LinkedAccessCodeLogin';
const classes = {
  form: `${PREFIX}-form`,
  submitRoot: `${PREFIX}-submit-root`,
  progressRoot: `${PREFIX}-progress-root`,
  progress: `${PREFIX}-progress`,
  paperError: `${PREFIX}-paperError`
};

const styles = ({ theme }) => ({
  [`&.${classes.form}`]: {
    width: "100%",
    marginTop: theme.spacing()
  },
  [`& .${classes.submitRoot}`]: {
    width: "40%",
    margin: "0 auto"
  },
  [`& .${classes.progressRoot}`]: {
    marginTop: "20px",
    width: "40%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  [`& .${classes.progress}`]: {
    width: "30px"
  },
  [`& .${classes.paperError}`]: {
    width: "60%",
    margin: "0 auto",
    backgroundColor: "#f44336",
    color: "white",
    textAlign: "center"
  }
});

const LinkedAccessCodeLogin = ({ loadingLogin, errorMessage }) => {
  return (
    <form className={classes.form}>
      <Paper className={classes.paperError}>{errorMessage}</Paper>

      <div className={classes.submitRoot}>
        {
          loadingLogin
            ? (
              <div className={classes.progressRoot}>
                <CircularProgress className={classes.progress}/>
              </div>
            )
            : null
        }
      </div>
    </form>
  );
};

export default styled(LinkedAccessCodeLogin)(styles);
