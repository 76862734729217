import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

const PasswordField = ({ inputProps, classes, ...props }) => {
  const [isVisible, setVisible] = useState(false);

  const onChangeVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <TextField
      {...props}
      InputProps={{
        ...inputProps,
        type: isVisible ? "text" : "password",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Toggle visibility" onClick={onChangeVisibility} size="large">
              {isVisible ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
