import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from '@mui/material/styles';
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "showio/actions";
import { hasRole, hasRoleOnlyCurrentSchedule } from "showio/reducers/SecurityContext";
import Roles from "showio/services/Roles";

const PREFIX = 'AppBar';
const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-app-bar`,
  navbarFlex: `${PREFIX}-navbar-flex`,
  margins: `${PREFIX}-margins`,
  logout: `${PREFIX}-logout`,
  link: `${PREFIX}-link`,
  active: `${PREFIX}-active`,
  timer: `${PREFIX}-timer`,
  switchView: `${PREFIX}-switch-view`
};

const styles = ({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1
  },
  [`& .${classes.appBar}`]: {
    backgroundColor: "#0086C2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative"
  },
  [`& .${classes.navbarFlex}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  [`& .${classes.margins}`]: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  [`& .${classes.logout}`]: {
    marginLeft: "10px",
    cursor: "pointer"
  },
  [`& .${classes.link}`]: {
    textDecoration: "none",
    color: "white"
  },
  [`& .${classes.active}`]: {
    borderBottom: "3px solid white",
    paddingBottom: "20px"
  },
  [`& .${classes.timer}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  [`& .${classes.switchView}`]: {
    marginRight: theme.spacing(),
    cursor: "pointer",
    color: "#fff"
  }
});

const Navbar = ({
  className,
  logout,
  switchView,
  view,
  history,
  children,
  userData,
  role,
  loadingLogout,
  match,
  securityContext
}) => {
  const logoutUser = () => logout(() => history.push("/"));
  const checkUrl = () => {
    const regex = /\/day\/.*\/schedules.*/;
    if (match.url.match(regex) || match.url === "/day/current/schedules") {
      return true;
    }

    return false;
  };
  const renderLeftNavbar = () => {
    const leftNavbar = [
      {
        name: "SHOWIO",
        link: "/schedules"
      },
      {
        name: "SCHEDULES",
        link: "/schedules"
      },
      {
        name: "TEMPLATES",
        link: "/templates"
      }
    ];

    if (hasRole(Roles.ROLE_READ_TEAM, securityContext)) {
      leftNavbar.push(
        {
          name: "GROUPS",
          link: "/groups"
        }
      );
    }
    leftNavbar.push(
      {
        name: "USERS",
        link: "/users"
      }
    );

    return (
      <Fragment>
        {leftNavbar.map((item, index) => {
          return (
            <Typography
              key={index}
              variant="body2"
              color="inherit"
              className={classes.margins}
            >
              {item.name === "SHOWIO" ? (
                <Link to={role ? "/current" : item.link} className={classes.link}>
                  {item.name}
                </Link>
              ) : (
                <>
                  {role ? null : (
                    <NavLink
                      activeClassName={classes.active}
                      className={classes.link}
                      to={item.link ? item.link : ""}
                    >
                      {item.name}
                    </NavLink>
                  )}
                </>
              )}
            </Typography>
          );
        })}
      </Fragment>
    );
  };

  const getOppositeView = viewSchedule => {
    return viewSchedule === "table" ? "timeline" : "table";
  };

  const onSwitchView = () => {
    switchView(getOppositeView(view));
  };

  return (
    <div className={className + " " + classes.root}>
      {loadingLogout && <LinearProgress/>}
      <AppBar position={role ? "static" : "sticky"}>
        <Toolbar className={classes.appBar}>
          <div className={classes.navbarFlex}>
            {checkUrl() ? (
              <Tooltip title="Switch view" aria-label="Switch view">
                <Icon
                  className={classes.switchView}
                  style={{ cursor: "pointer" }}
                  onClick={onSwitchView}
                >
                  swap_horiz
                </Icon>
              </Tooltip>
            ) : null}

            {renderLeftNavbar()}
          </div>
          <div className={classes.timer}>
            <Typography variant="h4" color="inherit">
              {children}
            </Typography>
          </div>
          <div className={classes.navbarFlex}>
            <Icon className={classes.margins}>account_box</Icon>
            <Typography variant="body2" color="inherit">
              {userData}
            </Typography>
            {!loadingLogout && (
              <Typography
                variant="body2"
                color="inherit"
                className={classes.logout}
                onClick={logoutUser}
              >
                Logout
              </Typography>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.securityContext.userDetails == null ? null : state.securityContext.userDetails.username,
    loadingLogout: state.auth.loadingLogout,
    role: hasRoleOnlyCurrentSchedule(state.securityContext),
    securityContext: state.securityContext,
    view: state.schedule.view
  };
}

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  withRouter
)(styled(Navbar)(styles));
