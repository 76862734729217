import { styled } from "@mui/material/styles";
import React from "react";
import DateTime from "showio/components/Date/Date";
import Timezone from "showio/components/Date/Timezone";
import useCurrentTime from "showio/hooks/useCurrentTime";

const PREFIX = 'ClockTimer';
const classes = {
  root: `${PREFIX}-root`,
  timezone: `${PREFIX}-timezone`
};

const styles = ({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  [`& .${classes.timezone}`]: {
    fontSize: "14px",
    marginLeft: "10px"
  }
});

const ClockTimer = ({ className }) => {
  const currentTime = useCurrentTime();

  return (
    <div className={className + " " + classes.root}>
      <DateTime date={currentTime} format={"HH:mm"}/>
      <span className={classes.timezone}>
        <Timezone/>
      </span>
    </div>
  );
};

export default styled(ClockTimer)(styles);
