import moment from "moment";
import isUtc from "showio/components/Date/isUtc";

const formatDate = ({ date, format, timezone }) => {
  if (date == null) {
    return "";
  }

  if (timezone == null) {
    throw new Error("Time zone cannot be empty");
  }

  if (isUtc({ timezone })) {
    return moment(date)
      .utc()
      .format(format);
  } else {
    return moment(date).format(format);
  }
};

export default formatDate;
