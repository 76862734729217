import CssBaseline from "@mui/material/CssBaseline";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "showio/App.css";
import AppBar from "showio/components/AppBar/AppBar";
import ClockTimer from "showio/components/ClockTimer/ClockTimer";
import Login from "showio/components/Login/Login";
import onlyCurrentScheduleRedirect from "showio/components/onlyCurrentScheduleRedirect";
import SecuredRoute from "showio/components/SecuredRoute";
import withSecurityContext from "showio/components/withSecurityContext";
import { hasRole } from "showio/reducers/SecurityContext";
import Roles from "showio/services/Roles";

const Schedules = onlyCurrentScheduleRedirect(
  React.lazy(() => import("showio/pages/schedules/SchedulesContainer"))
);
const Templates = onlyCurrentScheduleRedirect(
  React.lazy(() => import("showio/pages/templates/TemplatesContainer"))
);
const Groups = onlyCurrentScheduleRedirect(
  React.lazy(() => import("showio/pages/groups/GroupsContainer"))
);
const Users = onlyCurrentScheduleRedirect(
  React.lazy(() => import("showio/pages/users/UsersContainer"))
);
const Dashboard = onlyCurrentScheduleRedirect(
  React.lazy(() => import("showio/pages/dashboard/Dashboard"))
);
const CurrentDashboard = React.lazy(() =>
  import("showio/pages/dashboard-current/Dashboard")
);
const NotFound = React.lazy(() => import("showio/components/NotFound/NotFound"));

const App = ({ securityContext }) => {
  return (
    <div>
      <CssBaseline/>
      <ToastContainer/>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/access" component={Login}/>
        <Route exact path="/password" component={Login}/>
        <SecuredRoute
          path="/(.+)"
          render={() => (
            <div>
              <AppBar>
                <ClockTimer/>
              </AppBar>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <SecuredRoute
                    exact
                    path="/schedules"
                    component={Schedules}
                  />
                  <SecuredRoute
                    exact
                    path="/day/current/schedules"
                    component={CurrentDashboard}
                  />
                  <SecuredRoute
                    exact
                    path="/day/:date/schedules"
                    component={(Dashboard)}
                  />
                  <SecuredRoute
                    exact
                    path="/templates"
                    component={Templates}
                  />
                  {
                    hasRole(Roles.ROLE_READ_TEAM, securityContext)
                      ? <SecuredRoute
                        exact
                        path="/groups"
                        component={Groups}
                      />
                      : null
                  }
                  <SecuredRoute
                    path="/users"
                    component={Users}
                  />
                  <Route
                    path="/not-found"
                    component={NotFound}
                  />
                  <Redirect to="/not-found"/>
                </Switch>
              </Suspense>
            </div>
          )}
        />
      </Switch>
    </div>
  );
};

export default withSecurityContext(App);
