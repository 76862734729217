export const SET_PREFERENCES = "SET_PREFERENCES";
export const SET_LOGIN_METHOD = "SET_LOGIN_METHOD";
export const SET_TIME_ZONE = "SET_TIME_ZONE";

const buildSetPreferences = (preferences) => {
  return {
    type: SET_PREFERENCES,
    preferences: preferences
  };
};

export const setPreferences = (preferences) => (dispatch) => {
  if (preferences == null) {
    return;
  }

  localStorage.setItem("loginMethod", preferences.loginMethod);
  localStorage.setItem("timezone", preferences.timeZone + "");
  dispatch(buildSetPreferences(preferences));
};

const buildSetLoginMethod = (loginMethod) => {
  return {
    type: SET_LOGIN_METHOD,
    loginMethod: loginMethod
  };
};

export const setLoginMethod = (loginMethod) => (dispatch) => {
  localStorage.setItem("loginMethod", loginMethod);
  dispatch(buildSetLoginMethod(loginMethod));
};

const buildSetTimeZone = (timeZone) => {
  return {
    type: SET_TIME_ZONE,
    timeZone: timeZone
  };
};

export const setTimeZone = (timeZone) => (dispatch) => {
  localStorage.setItem("timezone", "" + timeZone);
  dispatch(buildSetTimeZone(timeZone));
};
