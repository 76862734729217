// @flow
import config from "showio/config";
import http from "showio/services/httpService";

const apiEndpointSignIn = config.apiEndpoint + "/authorization/sign_in";
const apiEndpointSignOut = config.apiEndpoint + "/authorization/sign_out";

type SignInRequest = {
  username: string,
  password: string,
  accessCode: string
};

const signIn = ({ username, password, accessCode }: SignInRequest): Promise<any> => {
  return http.post(apiEndpointSignIn, {
    username: username,
    password: password,
    access_code: accessCode,
    version: 2
  });
};

type SignOutRequest = {
  token: string
};

const signOut = ({ token }: SignOutRequest): Promise<any> => {
  const header = { headers: { Authorization: `Bearer ${token}` } };
  return http.post(apiEndpointSignOut, {}, header);
};

const authorizationRepository = {
  signIn: signIn,
  signOut: signOut
};

export default authorizationRepository;
